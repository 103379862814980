import { BASE_URL, httpClient } from "../config/develop-config";

const baseUrl = `${BASE_URL}/kci`;

export class kciDatasource {
  static getKci(params: object) {
    console.log("p", params);
    return httpClient
      .get(`${baseUrl}/kci`, { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static getKciById(kciId: string) {
    return httpClient
      .get(`${baseUrl}/kci/${kciId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static createKci(params: object) {
    return httpClient
      .post(`${baseUrl}/kci`, { KCI: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static updateKci(params: object) {
    return httpClient
      .patch(`${baseUrl}/kci`, { KCI: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static deleteKci(params: any) {
    return httpClient
      .delete(`${baseUrl}/kci`, {
        data: {
          kciId: params.kciId,
          isApproveDelete: params.isApproveDelete,
          isCancelApproveDelete: params.isCancelApproveDelete,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static copyKci(params: object) {
    return httpClient
      .post(`${baseUrl}/kci/copy`, params)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static moveKci(params: object) {
    return httpClient
      .post(`${baseUrl}/kci/change-target`, params)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static getKciLog(kciId: string) {
    return httpClient
      .get(`${baseUrl}/kci-log?kciId=${kciId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static approveKci(params: object) {
    return httpClient
      .post(`${baseUrl}/kci/approve`, params)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static getNotiList(params: object) {
    return httpClient
      .get(`${baseUrl}/notification`, { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static readKciNoti(notiId: string) {
    return httpClient
      .post(`${baseUrl}/notification/read/${notiId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static commentKci(params: object) {
    return httpClient
      .post(`${baseUrl}/kci-comment`, params)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static getCommentKciById(kciId: string) {
    return httpClient
      .get(`${baseUrl}/kci-comment/${kciId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static deleteComment = async (id: string) => {
    return await httpClient
      .delete(`${baseUrl}/kci-comment`, {
        data: {
          kciCommentId: id,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
